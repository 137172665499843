import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import vcShowcasImage from '../Image/ppreview-1.webp'
import cvcShowcasImage from '../Image/preview-2.webp'
import foscfsImage from '../Image/preview-3.webp'
import cscfsImage from '../Image/preview-4.webp'
import goscfsImage from '../Image/preview-5.webp'
import ffscfsImage from '../Image/preview-6.webp'
import swfscfsImage from '../Image/preview-7.webp'
import pefscfsImage from '../Image/preview-8.webp'
import uyupcfsImage from '../Image/preview-9.jpg'
import image10 from '../Image/preview-10.jpg'

const MainPage = () => {
  const sectionRefs = useRef([]);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.remove('opacity-0', 'translate-y-10');
            entry.target.classList.add('opacity-100', 'translate-y-0');
          }
        });
      },
      { threshold: 0.1 }
    );
    sectionRefs.current.forEach((section) => {
      if (section) observer.observe(section);
    });
    return () => observer.disconnect();
  }, []);

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <h1 className="text-5xl font-semibold font-spartan text-darkerGreen mt-5 mb-8 ml-40">Capital Fundraising Summit Showcases</h1>

      <div ref={(el) => (sectionRefs.current[1] = el)} className="flex flex-col md:flex-row bg-transparent text-black rounded-lg mb-12 ml-80 mr-64 opacity-0 translate-y-10 transition-opacity duration-1000 ease-linear">
        <div className="flex-shrink-0 md:w-1/4 lg:w-1/4 animate-fade-in">
          <img src={vcShowcasImage} alt="Global Visibility Icon" className="w-full h-full rounded-t-lg md:rounded-l-lg md:rounded-tr-none" />
        </div>
        <div className="flex flex-col justify-start md:w-3/4">
          <div className="bg-[#25d366] p-6 rounded-tr-lg md:rounded-bl-none text-white animate-fade-in opacity-0 animation-delay-1000">
            <Link to="/VCMarketingSection" className="hover:cursor-pointer">
              <h2 className="text-3xl ml-4 mt-4 font-spartan font-semibold uppercase hover:font-extrabold">
                VC Showcase
              </h2>
            </Link>
          </div>
          <div className="p-6 bg-transparent animate-fade-in opacity-0 animation-delay-1500">
            <p className="text-lg leading-relaxed font-spartan">
              Are you part of a venture capital firm ready to connect with high-impact investors, drive new partnerships, and uncover valuable opportunities in the global market?
            </p>
          </div>
        </div>
      </div>

      <div ref={(el) => (sectionRefs.current[2] = el)} className="flex flex-col md:flex-row-reverse bg-transparent text-black rounded-lg mb-12 ml-64 mr-72  opacity-0 translate-y-10 transition-opacity duration-1000 ease-linear">
        <div className="flex-shrink-0 md:w-1/4 lg:w-1/4 animate-fade-in">
          <img src={cvcShowcasImage} alt="Global Visibility Icon" className="w-full h-full rounded-t-lg md:rounded-r-lg md:rounded-tl-none" />
        </div>
        <div className="flex flex-col justify-start md:w-3/4">
          <div className="bg-[#25d366] p-6 rounded-tl-lg md:rounded-br-none text-white animate-fade-in opacity-0 animation-delay-1000">
            <Link to="/Cvcscfs" className="hover:cursor-pointer">
              <h2 className="text-3xl ml-4 mt-4 font-spartan font-semibold uppercase hover:font-extrabold ">
                Corporate Venture Capitalist Showcase
              </h2>
            </Link>
          </div>
          <div className="p-6 bg-transparent">
            <p className="text-lg leading-relaxed font-spartan animate-fade-in opacity-0 animation-delay-1000">
              Does your corporate venture arm aim to engage with startups and investors who can propel your innovation agenda?
            </p>
          </div>
        </div>
      </div>

      <div ref={(el) => (sectionRefs.current[3] = el)} className="flex flex-col md:flex-row bg-transparent text-black rounded-lg mb-12 ml-80 mr-64 opacity-0 translate-y-10 transition-opacity duration-1000 ease-linear">
        <div className="flex-shrink-0 md:w-1/4 lg:w-1/4 animate-fade-in">
          <img src={foscfsImage} alt="Global Visibility Icon" className="w-full h-full rounded-t-lg md:rounded-l-lg md:rounded-tr-none" />
        </div>
        <div className="flex flex-col justify-start md:w-3/4">
          <div className="bg-[#25d366] p-6 rounded-tr-lg md:rounded-bl-none text-white animate-fade-in opacity-0 animation-delay-1000">
            <Link to="/Foscfs" className="hover:cursor-pointer">
              <h2 className="text-3xl ml-4 mt-4 font-spartan font-semibold uppercase hover:font-extrabold">
                Family Offices Showcase
              </h2>
            </Link>
          </div>
          <div className="p-6 bg-transparent">
            <p className="text-lg leading-relaxed font-spartan animate-fade-in opacity-0 animation-delay-1000">
              Does your family office seek to align with transformative technologies and impactful investments that echo your values and legacy?
            </p>
          </div>
        </div>
      </div>
      <div ref={(el) => (sectionRefs.current[4] = el)} className="flex flex-col md:flex-row-reverse bg-transparent text-black rounded-lg mb-12 ml-64 mr-72  opacity-0 translate-y-10 transition-opacity duration-1000 ease-linear">
        <div className="flex-shrink-0 md:w-1/4 lg:w-1/4 animate-fade-in">
          <img src={cscfsImage} alt="Global Visibility Icon" className="w-full h-full rounded-t-lg md:rounded-r-lg md:rounded-tl-none" />
        </div>
        <div className="flex flex-col justify-start md:w-3/4">
          <div className="bg-[#25d366] p-6 rounded-tl-lg md:rounded-br-none text-white">
            <Link to="/Cscfs" className="hover:cursor-pointer">
              <h2 className="text-3xl ml-4 mt-4 font-spartan font-semibold uppercase hover:font-extrabold animate-fade-in opacity-0 animation-delay-1000">
                Corporate Showcase
              </h2>
            </Link>
          </div>
          <div className="p-6 bg-transparent">
            <p className="text-lg leading-relaxed font-spartan animate-fade-in opacity-0 animation-delay-1000">
              Is your corporation poised for expansion, innovation, or new ventures?
            </p>
          </div>
        </div>
      </div>

      <div ref={(el) => (sectionRefs.current[5] = el)} className="flex flex-col md:flex-row bg-transparent text-black rounded-lg mb-12 ml-80 mr-64 opacity-0 translate-y-10 transition-opacity duration-1000 ease-linear">
        <div className="flex-shrink-0 md:w-1/4 lg:w-1/4 animate-fade-in">
          <img src={goscfsImage} alt="Global Visibility Icon" className="w-full h-full rounded-t-lg md:rounded-l-lg md:rounded-tr-none" />
        </div>
        <div className="flex flex-col justify-start md:w-3/4">
          <div className="bg-[#25d366] p-6 rounded-tr-lg md:rounded-bl-none text-white">
            <Link to="/Goscfs" className="hover:cursor-pointer">
              <h2 className="text-3xl ml-4 mt-4 font-spartan font-semibold uppercase hover:font-extrabold animate-fade-in opacity-0 animation-delay-1000">
                Government Officials Showcase
              </h2>
            </Link>
          </div>
          <div className="p-6 bg-transparent">
            <p className="text-lg leading-relaxed font-spartan animate-fade-in opacity-0 animation-delay-1000">
              Are you representing a government project or public-private partnership with transformative potential?
            </p>
          </div>
        </div>
      </div>
      <div ref={(el) => (sectionRefs.current[6] = el)} className="flex flex-col md:flex-row-reverse bg-transparent text-black rounded-lg mb-12 ml-64 mr-72  opacity-0 translate-y-10 transition-opacity duration-1000 ease-linear">
        <div className="flex-shrink-0 md:w-1/4 lg:w-1/4 animate-fade-in">
          <img src={ffscfsImage} alt="Global Visibility Icon" className="w-full h-full rounded-t-lg md:rounded-r-lg md:rounded-tl-none" />
        </div>
        <div className="flex flex-col justify-start md:w-3/4">
          <div className="bg-[#25d366] p-6 rounded-tl-lg md:rounded-br-none text-white">
            <Link to="/Ffscfs" className="hover:cursor-pointer">
              <h2 className="text-3xl ml-4 mt-4 font-spartan font-semibold uppercase hover:font-extrabold animate-fade-in opacity-0 animation-delay-1000">
                Fund of Funds Showcase
              </h2>
            </Link>
          </div>
          <div className="p-6 bg-transparent">
            <p className="text-lg leading-relaxed font-spartan animate-fade-in opacity-0 animation-delay-1000">
              Is your fund of funds positioned to catalyze growth by investing in top-performing venture capital and private equity funds?
            </p>
          </div>
        </div>
      </div>
      <div ref={(el) => (sectionRefs.current[7] = el)} className="flex flex-col md:flex-row bg-transparent text-black rounded-lg mb-12 ml-80 mr-64 opacity-0 translate-y-10 transition-opacity duration-1000 ease-linear">
        <div className="flex-shrink-0 md:w-1/4 lg:w-1/4 animate-fade-in">
          <img src={swfscfsImage} alt="Global Visibility Icon" className="w-full h-full rounded-t-lg md:rounded-l-lg md:rounded-tr-none" />
        </div>
        <div className="flex flex-col justify-start md:w-3/4">
          <div className="bg-[#25d366] p-6 rounded-tr-lg md:rounded-bl-none text-white">
            <Link to="/Swfscfs" className="hover:cursor-pointer animate-fade-in">
              <h2 className="text-3xl ml-4 mt-4 font-spartan font-semibold uppercase hover:font-extrabold animate-fade-in opacity-0 animation-delay-1000">
                Sovereign Wealth Funds Showcase
              </h2>
            </Link>
          </div>
          <div className="p-6 bg-transparent">
            <p className="text-lg leading-relaxed font-spartan animate-fade-in opacity-0 animation-delay-1000">
              Are you representing a sovereign wealth fund looking to amplify national development and secure high-yield global investments?
            </p>
          </div>
        </div>
      </div>
      <div ref={(el) => (sectionRefs.current[8] = el)} className="flex flex-col md:flex-row-reverse bg-transparent text-black rounded-lg mb-12 ml-64 mr-72  opacity-0 translate-y-10 transition-opacity duration-1000 ease-linear">
        <div className="flex-shrink-0 md:w-1/4 lg:w-1/4">
          <img src={pefscfsImage} alt="Global Visibility Icon" className="w-full h-full rounded-t-lg md:rounded-r-lg md:rounded-tl-none" />
        </div>
        <div className="flex flex-col justify-start md:w-3/4 animate-fade-in">
          <div className="bg-[#25d366] p-6 rounded-tl-lg md:rounded-br-none text-white">
            <Link to="/Pefscfs" className="hover:cursor-pointer">
              <h2 className="text-3xl ml-4 mt-4 font-spartan font-semibold uppercase hover:font-extrabold animate-fade-in opacity-0 animation-delay-1000">
                Private Equity Funds Showcase
              </h2>
            </Link>
          </div>
          <div className="p-6 bg-transparent">
            <p className="text-lg leading-relaxed font-spartan animate-fade-in opacity-0 animation-delay-1000">
              Does your private equity fund aim to elevate portfolio companies, drive market leadership, and expand into new geographies?
            </p>
          </div>
        </div>
      </div>

      <div ref={(el) => (sectionRefs.current[9] = el)} className="flex flex-col md:flex-row bg-transparent text-black rounded-lg mb-12 ml-80 mr-64 opacity-0 translate-y-10 transition-opacity duration-1000 ease-linear">
        <div className="flex-shrink-0 md:w-1/4 lg:w-1/4 animate-fade-in">
          <img src={uyupcfsImage} alt="Global Visibility Icon" className="w-full h-full rounded-t-lg md:rounded-l-lg md:rounded-tr-none" />
        </div>
        <div className="flex flex-col justify-start md:w-3/4">
          <div className="bg-[#25d366] p-6 rounded-tr-lg md:rounded-bl-none text-white animate-fade-in opacity-0 animation-delay-1000">
            <Link to="/Uyupcfs" className="hover:cursor-pointer">
              <h2 className="text-3xl ml-4 mt-4 font-spartan font-semibold uppercase hover:font-extrabold">
                Unleash Your Unicorn's Potential
              </h2>
            </Link>
          </div>
          <div className="p-6 bg-transparent">
            <p className="text-lg leading-relaxed font-spartan animate-fade-in opacity-0 animation-delay-1000">
              Are you leading a unicorn—a pioneering startup on the verge of reshaping industries globally? Do you have an innovation that’s ready for the world stage?
            </p>
          </div>
        </div>
      </div>
      <div ref={(el) => (sectionRefs.current[10] = el)} className="flex flex-col md:flex-row-reverse bg-transparent text-black rounded-lg mb-12 ml-64 mr-72  opacity-0 translate-y-10 transition-opacity duration-1000 ease-linear">
        <div className="flex-shrink-0 md:w-1/4 lg:w-1/4 animate-fade-in">
          <img src={image10} alt="Global Visibility Icon" className="w-full h-full rounded-t-lg md:rounded-r-lg md:rounded-tl-none" />
        </div>
        <div className="flex flex-col justify-start md:w-3/4">
          <div className="bg-[#25d366] p-6 rounded-tl-lg md:rounded-br-none text-white animate-fade-in opacity-0 animation-delay-1000">
            <Link to="/Rfs" className="hover:cursor-pointer">
              <h2 className="text-3xl ml-4 mt-4 font-spartan font-semibold uppercase hover:font-extrabold">
                Corporate Showcase
              </h2>
            </Link>
          </div>
          <div className="p-6 bg-transparent">
            <p className="text-lg leading-relaxed font-spartan animate-fade-in opacity-0 animation-delay-1000">
              Showcase your corporate ventures and explore synergies with innovative companies and investors.
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-center my-8">
        <a
          href="https://forms.gle/rnmDRZMbpCMXjSSx9"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-primaryGreen text-white font-bold py-3 px-6 text-lg rounded shadow inline-block text-center animate-flash"
        >
          Apply Now
        </a>
      </div>

    </div>
  );
};

export default MainPage;

import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../Image/CAPITAL FUNDRAISING SUMMIT.png'

function Navbar() {
    return (
        <nav className="bg-primaryGreen font-spartan text-white px-6 flex items-center justify-between">
            <div className="flex items-center space-x-2">
                <Link to="/">
                    <img src={logo} alt="Logo" className="rounded-full h-1/6 w-1/6" />
                </Link>
            </div>
            <div className="flex space-x-6">
                <a href="/" className="hover:text-darkerGreen text-2xl transition-colors">
                    Menu
                </a>
            </div>
        </nav>
    );
}

export default Navbar;
